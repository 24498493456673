import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import MyLogo from "../components/Logo"
import MyLogoFixed from "../components/LogoFixed"
import MySignature from "../images/Signature.svg"

import "../Style/EssayPage.css"
import "../Style/CardPage.css"
import TitleUnderlined from "../components/TitleUnderlined"

const EnglishCardsY = ({data}) => (
 <div> 

    <SEO 
    title="English Words Cards"
    />

    <Helmet>

        <meta name="robots" content="noindex" />
        <link rel="subresource" href="../Style/EssayPage.css" />

        <meta name="twitter:description" content="Phil Murphy">
        </meta>

        <meta name="og:description" content="Phil Murphy">
        </meta>

        <meta name="description" content="Phil Murphy">
        </meta>

        <meta name="image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image:alt" content="Phil Murphy">
        </meta>

        <meta name="thumbnail" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="og:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

    </Helmet>


        <div className="CardsPageSettings">

        <div className="CardsPageMenu">
            <Link to="/english-cards-123456789"><div className="CardsPageMenuOptions">1</div></Link>
            <Link to="/english-cards-123456789-2"><div className="CardsPageMenuOptions">2</div></Link>
            <Link to="/english-cards-123456789-y"><div className="CardsPageMenuOptions">y</div></Link>
        </div>

                <div className="SectionPageCards">

                            <div className="PageCardSmallTitle">Set</div>
                            <div className="PageCardBigTitle">-ky -hy -y</div>

                                <div className="PageCardsGroup">


                                    <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Funky</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> smelly</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> cooked greens make the kitchen smell really funky</div>

                                    </div>

                                    <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Yacky</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> slang for disgusting</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> Uh man this watermelon is yacky</div>

                                    </div>

                                    <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Corky</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> having an unpleasant odor and taste</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> corky wine</div>

                                    </div>

                                    <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Wonky</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> not functioning correctly</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> I enjoy good health, apart from two wonky knees</div>

                                    </div>


                                    <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Sketchy</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> not thorough or detailed.</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> the information they had was sketchy</div>

                                    </div>

                                    <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Tricky</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> requiring care and skill because difficult or awkward.</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> applying eyeliner can be a tricky business</div>

                                    </div>

                                     <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Crampy</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> hard to understand or figure out</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> Those law terms are crampy</div>

                                    </div>

                                    <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Cranky</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> eccentric or strange.; bad-tempered; irritable.</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> he was cranky after eight hours of working</div>

                                    </div>

                                     <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Picky</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> fussy and hard to please.</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> they are becoming increasingly picky about where they stay</div>

                                    </div>

                                    <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Finicky</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> showing or requiring great attention to detail.</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> My teacher is finicky about spelling</div>

                                    </div>

                                    
                                    <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Clunky</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> solid, heavy, and old-fashioned.</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> even last year's laptops look clunky</div>

                                    </div>

                            
                                     <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Wacky</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> funny or amusing in a slightly odd or peculiar way.</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> a wacky chase movie</div>

                                    </div>

                                    <div className="PageCardContent">

                                        <div className="ContentCardBigTitle">Quirky</div>
                                        <div className="ContentCardSmallTitle">Meaning + Example</div>
                                        <div className="ContentCardMediumTitle"><b>i.e:</b> Quirky means weird, but in the good way</div>
                                        <div className="ContentCardMediumTitle"><b>e.g:</b> her sense of humour was decidedly quirky</div>

                                    </div>

                                </div>


                </div>









        </div>

    </div>
)

export default EnglishCardsY